<template>
  <b-modal
    id="seller-tracking-modal"
    @hidden="hideModal"
    centered
    @ok="handleOk"
  >
    <template #modal-title> เพิ่มรหัสพัสดุ </template>
    <b-overlay :show="$apollo.loading" rounded="sm">
      <b-form ref="form" class="form" @submit.stop.prevent="handleSubmit">
        <div class="form-group row mb-2">
          <label
            for="input-transportNumber"
            class="col-form-label text-right col-3"
          >
            รหัสพัสดุ
          </label>
          <div class="col-lg-8 col-9">
            <b-form-input
              id="input-transportNumber"
              v-model="form.transportNumber"
              required
              :state="validateState('transportNumber')"
              placeholder=""
            ></b-form-input>
          </div>
        </div>
      </b-form>
    </b-overlay>
  </b-modal>
</template>
<script>
import {
  SELLER_ORDER_TRACKING_CREATE,
  SELLER_ORDER_TRACKING_UPDATE
} from "@/graphql/seller_order";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  props: ["refetch", "_id", "orderId", "onClose"],
  data() {
    return {
      form: {
        transportNumber: "",
        dept_desc: "",
        no: 0
      }
    };
  },
  validations: {
    form: {
      transportNumber: {
        required
      },
      dept_desc: {},
      no: {
        required
      }
    }
  },
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async handleSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const transportNumber = this.$v.form.transportNumber.$model;
      const input = {
        transportNumber
      };
      if (this._id) {
        input.transportId = this.form.transportId;
        const {
          data: { seller_order_tracking_update }
        } = await this.$apollo.mutate({
          mutation: SELLER_ORDER_TRACKING_UPDATE,
          variables: { input }
        });
        const { isSuccess, message } = seller_order_tracking_update;
        if (!isSuccess) {
          this.$message.error(message);
        } else {
          this.$message.success(message);
          if (this.refetch) {
            this.refetch();
          }
          this.$nextTick(() => {
            this.$bvModal.hide("seller-tracking-modal");
          });
        }
        return;
      }
      const {
        data: { seller_order_tracking_create }
      } = await this.$apollo.mutate({
        mutation: SELLER_ORDER_TRACKING_CREATE,
        variables: { input, orderId: this.orderId }
      });
      const { isSuccess, message } = seller_order_tracking_create;
      if (!isSuccess) {
        this.$message.error(message);
      } else {
        this.$message.success(message);
        if (this.refetch) {
          this.refetch();
        }
        this.$nextTick(() => {
          this.$bvModal.hide("seller-tracking-modal");
        });
      }
    },
    hideModal() {
      this.form.transportNumber = "";
    }
  }
};
</script>
